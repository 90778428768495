import React from "react";
import "../Css/honor.css";
import doctor1 from "../Images/home/dr-ashok2.jpg";
import doctor2 from "../Images/home/dr.lalit3.jpg";
import doctor3 from "../Images/home/dr-subrat_.jpg";
import doctor4 from "../Images/home/dr.sachin.jpeg";
import doctor5 from "../Images/home/dr-vivek-jawali.jpg";
import doctor6 from "../Images/home/dr-vijaay.jpg";
import doctor7 from "../Images/home/aditya.jpg";
import doctor8 from "../Images/home/Dr.-Parimala-Devi.png";
import doctor9 from "../Images/home/Manjinder_Sandhu.jpg";
import doctor10 from "../Images/home/Dr-Mohan-Keshavamurthy.jpg";

const Honor = () => {
  const doctors = [
    {
      name: "Dr. Ashok Seth",
      image: doctor1,
      specialization: "Interventional Cardiology",
      hospital: "Fortis Escorts Heart Institute, New Delhi",
      achievements: [
        "Dr. Seth has been awarded by the National Honour of Padma Shri in 2003",
        "Padma Bhushan in 2015 by the President of India",
        "B.C. Roy National Award for the most Eminent Medical Person by the President of India in January 2017.",
      ],
      profileLink:
        "https://rxone.app/profile/dr-ashok-seth-cardiac-ablation-cardiac-catheterisation-cardioversion-carotid-angioplasty-and-stenting-coronary-angioplasty-bypass-surgery-mitral-heart-valve-replacement-ct-angiogram-implantable-cardioverter-defibrillators-icds-pacemaker-implantation-acute-aortic-dissection-cardiac-rehabilitation-heart-conditions-asd-vsd-device-closure-hypertension-treatment-patent-foramen-ovale-new-delhi",
    },
    {
      name: "Dr. Lalit Kumar",
      image: doctor2,
      specialization: "Medical Oncology",
      hospital: "Artemis Hospital, Gurgaon",
      achievements: [
        "Dr. Lalit Kumar has been awarded by the National Honour of Padma Shri Award (2014)",
        "Dr. B.C. Roy Award (2008) - the highest award by the Indian government in the medical category",
        "Indian Council of Medical Research (ICMR) Award",
      ], profileLink:
      "https://rxone.app/profile/dr-lalit-kumar-bmt-oncology-gurgaon",
 
    },
    {
      name: "Dr. Subrat Kumar Acharya",
      image: doctor3,
      specialization: "Hepatology",
      hospital: "Fortis Escorts, Delhi",
      achievements: [
        "Dr. Subrat Kumar has been honoured with several awards and accolades. Prominent among them is the Padma Shree which he received in 2014.",
        "Dr. Subrat Kumar Acharya has also received the prestigious Samanta Chandrasekhar & JC Bose awards",
      ],
      profileLink:
      "https://rxone.app/profile/dr-subrat-kumar-acharya-gastroenterology-and-hepatobiliary-sciences-|-gastroenterology-and-hepatobiliary-sciences-new-delhi",
 
    },
    {
      name: "Dr. Sachin Karkamkar",
      image: doctor4,
      specialization: "Orthopedics",
      hospital: "Sahyadri Hospital, Pune",
      achievements: [
        "Padmashree Dr. KH Sancheti Gold Medal (MS Ortho)",
        "Col KD Khare Award for Orthopaedic Excellence",
      ],
      profileLink:
      "https://rxone.app/profile/dr-sachin-karkamkar-sports-medicine-knee-hip-and-shoulder-replacement-surgery-knee-and-shoulder-arthroscopy-complex-trauma-and-adult-reconstruction-surgery-arthritis-management-awar-acl-pcl-reconstruction-physiotherapy-for-sports-injury-rehabilitation-pune",
 
    },
    {
      name: "Dr. Vivek Jawali",
      image: doctor5,
      specialization: "Cardiovascular Surgery",
      hospital: "Fortis Hospitals, Bangalore",
      achievements: [
        "B C Roy Award for Medical Excellence",
        "Lifetime Achievement Award from Harvard University for Medical Excellence in India",
        "Global Recognition for ‘Awake’ Open Heart Surgery",
      ],
      profileLink:
      "https://rxone.app/profile/dr-vivek-jawali-cardiothoracic-surgeon-bangalore",
 
    },
    {
      name: "Dr. G.R Vijay Kumar",
      image: doctor6,
      specialization: "General Surgery",
      hospital: "Fortis Hospital, Kolkata",
      achievements: [
        "B.C. Roy Memorial Award for Best Student, Medical College, Calcutta, 1989",
        "University Gold Medal in Surgery, Calcutta University, 1988.",
        "University Gold Medal in Obstetrics and Gynecology, Calcutta University, 1988",
      ],
      profileLink:
      "https://rxone.app/profile/dr-gr-vijay-kumar-neurosurgery-kolkata",
 
    },
    {
      name: "Dr Aditya Gupta",
      image: doctor7,
      specialization: "Neurosurgery",
      hospital: "Artemis Hospital, Gurgaon",
      achievements: [
        "Chief of Army Staff Award, 2012.",
        "BOYSCAST Fellowship Award, President of India, 2006.",
        "Best Research Paper Award, IES, 1999.",
      ],
      profileLink:
      "https://rxone.app/profile/dr-aditya-gupta-neurosurgeon-gurgaon",
 
    },
    {
      name: "Dr. Parimala Devi",
      image: doctor8,
      specialization: "Obstetrics and Gynecology",
      hospital: "Fortis Hospital, Bangalore",
      achievements: [
        "Mother Teresa Award.",
        "Aryabhata National Award – Human Service",
        "Gold Medal for Active Management of Septic Abortion – Rotary Club, Bangalore – 1998.",
      ],
      profileLink:
      "https://rxone.app/profile/dr-parimala-devi-gynecologist-obstetrician-infertility-specialist-laparoscopic-surgeon-obs-_-gyn-bangalore",
 
    },
    {
      name: "Dr. (Col) Manjinder Sandhu",
      image: doctor9,
      specialization: "Cardiology",
      hospital: "Fortis Memorial Research Institute",
      achievements: [
        "Times Health Care Achievers Award 2018.",
        "Commendation, Ministry of Social Welfare, Sri Lanka - 2003",
        "Lt Gen Inder Singh Gold Medal - 1994",
      ],
      profileLink:
      "https://rxone.app/profile/dr-col-manjinder-sandhu-complex-coronary-interventions-including-rotablator-ivl-and-laser-endovascular-procedures-such-as-evar-tevar-and-coarctation-of-aorta-peripheral-interventions-including-ilio-femoral-and-renal-denervation-intracoronary-imaging-techniques-like-ivus-and-oct-devices-including-pacemaker-icd-crt-d-and-ccm-cardiac-contractility-modulation-valvular-interventions-such-as-bmv-tavr-mitraclip-and-tricuspid-valve-procedures-gurgaon",
 
    },
    {
      name: "Dr. Mohan Keshavamurthy",
      image: doctor10,
      specialization: "Urology",
      hospital: "Fortis Hospital, Bangalore",
      achievements: [
        "3000 laser-enabled Transurethral prostate procedures (LASER TURP)",
        "2500 laser fragmentation of kidney (RIRS) and ureteric stones(URS)",
        "2500 kidney transplants and 75 pancreas transplants",
      ],
      profileLink:
      "https://rxone.app/profile/dr-mohan-keshavamurthy-implantation-of-flexible-and-inflatable-penile-prosthesis-and-penile-lengthening-procedures-artificial-sphincter-insertion-and-redo-hypospadias-surgery-pelvic-_-urologic-surgeries-transurethral-prostate-procedures-laser-turp-kidney-_-pancreas-transplant-uro-oncological-procedures-bangalore",
 
    },
  ];

  const extendedDoctors = [...doctors, ...doctors];

  return (
    <div className="honor-section-container">
      <h2 className="honor-heading">Decorated Specialists</h2>
      <p className="honor-description">
        We are proud to be associated with top doctors and specialists across
        India. Below is the list of decorated doctors, who have been awarded
        and recognized at the national level.
      </p>
      <div className="marquee-container">
        <div className="marquee">
          {extendedDoctors.map((doctor, index) => (
            <a
              href={doctor.profileLink}
              target="_blank"
              rel="noopener noreferrer"
              className="honor-card-link"
              key={index}
            >
              <div className="honor-card-custom">
                <div className="honor-image-container">
                  <img
                    src={doctor.image}
                    alt={doctor.name}
                    className="honor-doctor-image"
                  />
                  <div className="honor-achievement-overlay">
                    <ul className="honor-achievement-list">
                      {doctor.achievements.map((achievement, idx) => (
                        <li key={idx} className="honor-achievement-item">
                          {achievement}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="honor-doctor-details">
                  <div className="honor-doctor-name">{doctor.name}</div>
                  <div className="honor-doctor-specialization">
                    {doctor.specialization}
                  </div>
                  <div className="honor-doctor-hospital">{doctor.hospital}</div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Honor;