import { useState, useEffect, useRef } from "react";
import backgroundimg from "../Images/banner-bg.jpg";
import emailjs from "emailjs-com";
import '../Css/oldcare.css';
import { countriesList } from "../utility/constants";
import { useMediaQuery } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { medicals } from "./config";
import NukaCarousel from "../tools/NukaCarousel";
import { faqs } from "./config";
import { hip } from "./config";
import { faqsss } from "./config";
import docgirl from "../Images/home/hip.png";
import hipani from "../Images/home/hip-animation.mp4";
import belt1 from "../Images/home/belt1.png";
import belt2 from "../Images/home/belt2.png";
import belt3 from "../Images/home/belt3.png";
import {
    TextField,
    Button,
    Container,
    makeStyles,
    FormControl,
    InputLabel,
    InputAdornment,
    Input,
    MenuItem,
    FormHelperText,
    Select,
    Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: theme.spacing(4),
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
        },
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
        },
    },
    button: {
        margin: theme.spacing(2, 0),
        backgroundColor: "#199a8e",
        "&:hover": {
            // Remove hover effect
            backgroundColor: "#199a8e",
        },
    },
    salutationFormControl: {
        width: "27%", // Adjust the width of the salutation select
    },
    gender: {
        width: "30%",
    },
    nameContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    nameField: {
        background: "white",
    },
    select: {
        border: "none", // Remove border
        width: "50px", // Adjust the width as needed
    },
}));

const Oldcare = () => {
    const classes = useStyles();
    const [loader, setLoader] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [phoneCode, setPhoneCode] = useState("+91");
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [pin, setPin] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;
    const videoRef = useRef(null);
    const sectionRef = useRef(null);
    const content = [
        {
          title: "Delivery of Devices and Services",
          text: "The device & services will be delivered by the Third Party Vendor or independent contractors. You accept and acknowledge that the Third Party Vendor or such other transporter/courier/delivery personnel, engaged by the Third Party Vendor or RxOne, shall be independent contractors in charge of the delivery of the Device and Services from the concerned Third Party Vendor to the address notified by You, with no control over the Devices and Services and no additional obligation apart from standard delivery obligations and duty of care.",
        },
        {
          title: "Role of RxOne",
          text: "You further accept and acknowledge that RxOne does not engage in the distribution of the Devices and Services and may provide the services of a third-party transporter/courier delivery personnel for the purposes of delivery of Devices and Services from the concerned Third Party Pharmacy to the address notified by You.",
        },
      ];
    
      useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              // Play the video when the section enters the viewport
              if (videoRef.current) {
                const iframe = videoRef.current.contentWindow;
                iframe.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
              }
            } else {
              // Pause the video when the section leaves the viewport
              if (videoRef.current) {
                const iframe = videoRef.current.contentWindow;
                iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', "*");
              }
            }
          },
          { threshold: .1 } // Adjust threshold for triggering
        );
    
        if (sectionRef.current) {
          observer.observe(sectionRef.current);
        }
    
        return () => {
          if (sectionRef.current) {
            observer.unobserve(sectionRef.current);
          }
        };
      }, []);
  
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
    
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
    const images = [belt1, belt2, belt3];
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = (e) => {
            setScrollY(e.target.scrollTop); // Track the scroll position within the box
        };

        const scrollBox = document.getElementById("parallax-scroll-box");
        scrollBox.addEventListener("scroll", handleScroll);

        return () => {
            scrollBox.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const isValidPhoneNumber = (phoneNumber) => {
        const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
        return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
    };
    const [expandedQuestions, setExpandedQuestions] = useState([]);

    const handlesubmit = (e) => {
        e.preventDefault();
        const serviceid = "service_7yxtqsn";
        const templateid = "template_x3wusd7";
        const publickey = "4UpyJJj3P5JGeMZFz";
        const messageContent = `
        Type: ' Hip Pro+ order'
        Name: ${name}
        Number: ${phoneCode}${phone}
        City: ${city}
        Address: ${message}
        Pin: ${pin}
    `;

        const templateparams = {
            from_name: name,
            from_email: email,
            to_name: "web",
            message: messageContent, // Include the messageContent here
        };

        emailjs
            .send(serviceid, templateid, templateparams, publickey)
            .then((response) => {
                console.log("email set successfully", response);
              
                setName("");
                setEmail("");
                setPhone("");
                setCity("");
                setPin("");
                setMessage("");
            })
            .catch((error) => {
                console.log("error sending email", error);
            });
            
    };

    const toggleQuestion = (id) => {
        if (expandedQuestions.includes(id)) {
            setExpandedQuestions(expandedQuestions.filter((qid) => qid !== id));
        } else {
            setExpandedQuestions([...expandedQuestions, id]);
        }
    };

    const titlle = 
    "Hip Pro Fall Prevention Belt - Ensuring Safety and Mobility | RxOne";
const desc = 
    "Explore the Hip Pro Fall Prevention Belt, designed to enhance safety and mobility. Ideal for individuals at risk of falls, providing superior support and comfort. Available now at RxOne.";

    return (
        <>
            <Helmet>{<title>{titlle}</title>}</Helmet>
            <Helmet>{<meta name="description" content={desc} />}</Helmet>

            <section data-aos-duration="3000" className="ms-cureways-section">
                <div
                    style={{
                        padding: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
                        marginTop: isMobile ? "1rem" : "0",
                        marginTop: "4rem",
                        marginBottom: "-6rem",
                    }}
                >
                   <div className="hip-pro-plus-container">
  <h1 className="hip-pro-plus-heading">
    Hip Pro+: The Elderly's Guardian Smart Belt
  </h1>
  <div className="hip-pro-plus-content">
    <p className="hip-pro-plus-text">
      An innovative solution designed to prioritize the safety and well-being
      of the elderly. With cutting-edge technology and seamless connectivity,
      we aim to provide unparalleled comfort and peace of mind to our users.
    </p>
    <div className="hip-pro-plus-contact">
      <span className="hip-pro-plus-contact-text">
        Fill up the form or call us at +91-770-393-4446 to place your order now
      </span>
      <img src={docgirl} alt="Doctor illustration" className="hip-pro-plus-image" />
    </div>
  </div>
</div>


                    <div style={{ float: "inline-end" }}>
                        <Container component="main" maxWidth="xs">
                            <div
                                className="provider__revolutionize__item"
                                style={{
                                    backgroundColor: "#fff",
                                    width: isMobile ? "90%" : "105%",
                                    borderRadius: "55px",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    marginRight:"20px",
                                    marginTop:"0rem",
                                }}
                            >
                                <form
                                    style={{ textAlign: "center", width: "130%",}}
                                    onSubmit={handlesubmit}
                                >
                                       <h2 style={{

                        color:"#0F8894",
                        fontFamily:"poppins",
                        marginTop:"-2rem",
                }}
                
            >Order Now</h2>
                                    <TextField
                                        id="firstName"
                                        name="first_name"
                                        label="Full Name"
                                        variant="filled"
                                        style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                                        required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        autoFocus
                                        fullWidth
                                        className={classes.nameField}
                                    />
                                    <FormControl
                                        variant="filled"
                                        fullWidth
                                        className={classes.nameField}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                        <Select
                labelId="phoneCode"
                id="demo-simple-select"
                value={phoneCode} // Bound to the selected country code
                onChange={(e) => setPhoneCode(e.target.value)} // Update state on selection
            >
                {countriesList.map((item, index) => (
                    <MenuItem
                        key={item.code + "-" + index} // Ensure a unique key for each item
                        value={item.code}
                    >
                        {item.code} {/* Display the country code */}
                    </MenuItem>
                ))}
            </Select>

                                            <InputLabel
                                                htmlFor="phoneNumber"
                                                style={{ marginLeft: "101px" }}
                                            >
                                                Phone Number*
                                            </InputLabel>
                                            <Input
                                                id="phoneNumber"
                                                name="phone"
                                                label="Phone Number"
                                                required
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                error={phoneError}
                                                style={{ flex: 1, marginLeft: 6, width: "100px" }} // Adjust the margin and flex properties
                                                className={classes.nameField}
                                            />
                                        </div>
                                        {phoneError && (
                                            <FormHelperText error>
                                                Please enter a valid phone number.
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <TextField
                                        id="pin"
                                        name="pin"
                                        label="Pin Code"
                                        variant="filled"
                                        value={pin}
                                        onChange={(e) => setPin(e.target.value)}
                                        style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                                        required
                                        fullWidth
                                        className={classes.nameField}
                                    />

                                    <TextField
                                        id="chiefComplaints"
                                        name="message"
                                        label="Address"
                                        variant="filled"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                        fullWidth
                                        style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                                        className={classes.nameField}
                                    />

                                    <TextField
                                        id="city"
                                        name="city"
                                        label="Enter your city"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        variant="filled"
                                        required
                                        fullWidth
                                        style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                                        className={classes.nameField}
                                    />
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        fullWidth
                                    >
                                        Submit
                                    </Button>
                                </form>
                            </div>
                        </Container>
                    </div>
                </div>
            </section>
            <section
      ref={sectionRef}
      className="video-section"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: "#fff",
        padding: "20px",
        marginTop: isMobile ? "12rem" : "15rem",
      }}
    >
      <h1
        style={{
          fontWeight: "800",
          fontSize: isMobile ? "20px" : "36px",
          marginBottom: "1rem",
          color: "rgb(15, 136, 148)",
          textAlign: "center",
          fontFamily: "Poppins",
        }}
      >
        How Hip Pro+ Keeps You Safe
      </h1>
      <iframe
        ref={videoRef}
        className="section-video"
        src="https://www.youtube.com/embed/sB_IvXRzPD8?enablejsapi=1&mute=1&controls=0&modestbranding=1&rel=0&showinfo=0"
        title="How Hip Pro+ Keeps You Safe"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          width: "100%",
          maxWidth: "1000px",
          height: "auto",
          aspectRatio: "16/9",
          marginBottom: "2rem",
          borderRadius: "10px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      ></iframe>

      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          gap: "1rem",
          width: "100%",
          maxWidth: "900px",
          marginBottom: "230px",
        }}
      >
        <div
          style={{
            flex: "1",
            padding: "2rem",
            background: "#fff",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            marginTop: "-.1rem",
          }}
        >
          <h2 style={{ fontSize: "1rem", marginTop: "-10px" }}>
            Advanced Technology Integration
          </h2>
          <p style={{ fontSize: "0.85rem", color: "#555" }}>
            Hip Pro+ combines electronic hardware with a sophisticated AI
            algorithm, enabling real-time analysis of movements at a frequency
            of up to 100 times per second.
          </p>
        </div>
        <div
          style={{
            flex: "1",
            padding: "1rem",
            background: "#fff",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          <h2 style={{ fontSize: "1rem", marginBottom: "1rem" }}>
            Precise Motion Detection
          </h2>
          <p style={{ fontSize: "0.85rem", color: "#555" }}>
            Utilizing motion-sensing technology embedded within a lightweight
            and comfortable belt, Hip Pro+ accurately identifies the wearer's
            posture and activity characteristics, including assessing the
            likelihood of falls.
          </p>
        </div>
        <div
          style={{
            flex: "1",
            padding: "1rem",
            background: "#fff",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          <h2 style={{ fontSize: "1rem", marginBottom: "1rem" }}>
            Intelligent Fall Prevention
          </h2>
          <p style={{ fontSize: "0.85rem", color: "#555" }}>
            Upon detection of posture or activity change, the belt quickly
            inflates cushions around the hips to prevent falls, using machine
            learning for accurate posture detection.
          </p>
        </div>
      </div>
    </section>

        <section
    style={{
        position: "relative",
        width: "100%",
        height: "100vh", // Full viewport height
    overflow: "hidden",
  }}
>
  {/* Scrollable Box */}
  <div
    id="parallax-scroll-box"
    style={{
      position: "relative",
      width: "100%",
      height: "100%",
      overflowY: "scroll",
      scrollBehavior: "smooth",
    }}
  >
    {/* Content Inside the Scroll Box */}
    <div
      style={{
        position: "relative",
        height: window.innerWidth <= 768 
        ? `${images.length * 70}vh` // Mobile height calculation
        : `${images.length * 140}vh`, // Desktop height calculation // Add vertical space (130% of viewport height per image)
      }}
    >
      {images.map((image, index) => {
       const imageOffset = index * (isMobile ? 80 : 130); 
        const parallaxStyle = {
          position: "absolute",
          top: `${imageOffset}vh`, // Add vertical spacing between images
          left: 0,
          width: "100%", // Cover full width of the viewport
          height: window.innerWidth <= 768 
          ? "78vh" // Mobile: Adjust to smaller viewport height
          : "140vh",           backgroundImage: `url(${image})`,
          backgroundSize: "contain", // Adjust to fit the full image
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        };

        return <div key={index} style={parallaxStyle}></div>;
      })}
    </div>
  </div>
</section>

     
<section className="terms-container">
      <h1 className="terms-heading">Terms and Conditions</h1>
      <div className="terms-content">
        {content.map((item, index) => (
          <div key={index} className="terms-item">
            <p>
              <strong>{item.title}: </strong>
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </section>
    <h5
      
      style={{
        fontWeight: "800",
fontSize: isMobile ? "20px" : "36px",

        marginBottom: "1rem",
        
        color: "rgb(15, 136, 148)",
        textAlign: "center",
        fontFamily:"poppins",
    }}
    >
      Frequently Asked Questions
    </h5>

    <ul style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
      {hip.map((faq) => (
        <li className="languages card" key={faq.id}>
          <div>
            <strong>{faq.question}</strong>
            <button
              onClick={() => toggleQuestion(faq.id)}
              style={{
                border: "none",
                background: "none",
                display: "flex",
                justifyContent: "end",
                width: "100%",
                marginTop: "-22px",
              }}
            >
              <span>{expandedQuestions.includes(faq.id) ? "▲" : "▼"}</span>
            </button>
          </div>
          {expandedQuestions.includes(faq.id) && <p>{faq.answer}</p>}
        </li>
      ))}
    </ul>
    </>
  );
};
export default Oldcare;
