import VHImage from "../Images/provider/virtual_hospital.png";
import PEImage from "../Images/provider/patient_engagement.png";
import PMImage from "../Images/provider/healthcare_monitoring.png";
import OPDAutomationImage from "../Images/provider/automation_opd.png";
import CMImage from "../Images/provider/social-care-management.png";
import PIImage from "../Images/provider/doctor-improvement.png";
import ScaleCSImage from "../Images/provider/scale-care-services.png";
import EnsureMCImage from "../Images/provider/ensure-mandatory-compliance.png";
import EasyIntegrationImage from "../Images/provider/easy-integration.png";
import pic1 from "../Images/provider/Picture1.png";
import pic2 from "../Images/provider/Picture2.png";
import pic3 from "../Images/provider/Picture3.png";
import pic4 from "../Images/provider/Picture4.png";
import screen from "../Images/home/screen1.png";
import profile from "../Images/home/profile.png"
import chat from "../Images/home/chat+.png";
import medicalbotle from "../Images/home/midicbotl.png";
import testtube from "../Images/home/testtube1.png";
import cred from "../Images/home/addred1.png";
import pre1 from "../Images/home/pre.png";
import pre2 from "../Images/home/pre2.png";
import pre3 from "../Images/home/pre3.png";
import path1 from "../Images/home/path1.png"
import path2 from "../Images/home/path2.png"
import path3 from "../Images/home/path3.png"
import path4 from "../Images/home/path4.png"

export const revolutionize = [
  {
    icon: VHImage,
    alt: "Revolutionizing Healthcare",
    title: "End to End Virtual Hospital/Clinic",
    text: "Offer wholistic care services through digital platform. Tele-consultation, ePharmacy, eLab, Virtual Receptionist and much more.",
  },
  {
    icon: PEImage,
    alt: "Revolutionizing Healthcare",
    title: "Continuous Patient Engagement",
    text: "Engage your end usersuniquely, customnotification, broadcasting, follow-up automation.",
  },
  {
    icon: PMImage,
    alt: "Revolutionizing Healthcare",
    title: "Patient Monitoring & Alerts",
    text: "Remote Patient Monitoring, VitalsCharts, Critical Alertson vitals, care advisory and support services.",
  },
  {
    icon: OPDAutomationImage,
    alt: "Revolutionizing Healthcare",
    title: "OPD Automation",
    text: "AI enabled Walk-InQueue Management, Unique QR for Facility, Electronic Prescriptions, DigitalPayments & Settlements",
  },
  {
    icon: CMImage,
    alt: "Revolutionizing Healthcare",
    title: "Care Management",
    text: "Self registration forPatients & Family, ABHA Integration, Patient Progress Analytics, Health Records Locker, Vitals Tracking",
  },
  {
    icon: PIImage,
    alt: "Revolutionizing Healthcare",
    title: "Practice Improvement",
    text: "Digital prescription with clinical findings, prescription quality checks automation,unified calendar management, compliance reporting",
  },
  {
    icon: ScaleCSImage,
    alt: "Revolutionizing Healthcare",
    title: "Scale care services",
    text: "Custom marketing campaign, Cross/Upselling, care services expansion, Referrals & Rewards",
  },
  {
    icon: EnsureMCImage,
    alt: "Revolutionizing Healthcare",
    title: "Ensure mandatory compliance",
    text: "Comply with NMC – Telemedicine guidelines, NDHM – ABHA Integration,SHA 256 Data Encryption, HIPAA Compliant",
  },
  {
    icon: EasyIntegrationImage,
    alt: "Revolutionizing Healthcare",
    title: "Easy Integrationand White Labelling",
    text: "Integrate with existing applications or HIS systems, also available as White Labelled Patient Engagement App(Android & IOS)",
  },
];

export const medicals = [
  {
    icon: pre1,
    alt: "Revolutionizing Healthcare",
    title: "Upload Prescription",
    text: "Fill the form & upload Prescription.",
  },
  {
    icon: pre2,
    alt: "Revolutionizing Healthcare",
    title: "Instant Booking",
    text: "RxOne expert will call you and confirm delivery.",
  },
  {
    icon: pre3,
    alt: "Revolutionizing Healthcare",
    title: "Doorstep Delivery",
    text: "Your medicines will be delivered at doorstep.",
  },
  
];
export const pathology = [
  {
    icon: path1,
    alt: "Revolutionizing Healthcare",
    title: "Instant Booking",
    text: "Fill the form and request for sample collection",
  },
  {
    icon: path2,
    alt: "Revolutionizing Healthcare",
    title: "Confirm Tests",
    text: "Our expert will call you to confirm tests",
  },
  {
    icon: path3,
    alt: "Revolutionizing Healthcare",
    title: "Sample Collection",
    text: "Our expert will call you to confirm tests",
  },
  {
    icon: path4,
    alt: "Revolutionizing Healthcare",
    title: "Smart Report",
    text: "Get the report on your mobile.",
  }
];

export const Customdetails = [
  {
    icon: screen,
    alt: "Revolutionizing Healthcare",
    title: "Consult Now",
    text: "Consult with top specialists from comfort of your home.",
  },
  {
    icon: profile,
    alt: "Revolutionizing Healthcare",
    title: "Seek Advice",
    text: "Seek treatment advice from RxOne experts.",
  },
  {
    icon: chat,
    alt: "Revolutionizing Healthcare",
    title: "Consult Now",
    text: "Take Second Opinion from trusted specialists.",
  },
  {
    icon: medicalbotle,
    alt: "Revolutionizing Healthcare",
    title: "Order Now",
    text: "Order medicines at discounted prices.",
  },
  {
    icon: testtube,
    alt: "Revolutionizing Healthcare",
    title: "Book Now",
    text: "Oder Pathology Tests from trusted providers.",
  },
  {
    icon: cred,
    alt: "Revolutionizing Healthcare",
    title: "Know More",
    text: "Seek medical loan from at attractive rates.",
  },
];

export const revolutionizes = [
  {
    icon: pic2,
    alt: "Revolutionizing Healthcare",
    title: "Fill form & request callback",
    // text: "Integrate with existing applications or HIS systems, also available as White Labelled Patient Engagement App(Android & IOS)",
  },
  {
    icon: pic1,
    alt: "Revolutionizing Healthcare",
    title: "Our Medical Expert will call you",
    // text: "Comply with NMC – Telemedicine guidelines, NDHM – ABHA Integration,SHA 256 Data Encryption, HIPAA Compliant",
  },
  {
    icon: pic3,
    alt: "Revolutionizing Healthcare",
    title: "Our expert will share a treatment proposal",
    // text: "Integrate with existing applications or HIS systems, also available as White Labelled Patient Engagement App(Android & IOS)",
  },
  {
    icon: pic4,
    alt: "Revolutionizing Healthcare",
    title: "Experience stress free medical journey",
    // text: "Integrate with existing applications or HIS systems, also available as White Labelled Patient Engagement App(Android & IOS)",
  },
];

export const faqs = [
  {
    id: 0,
    question: "What is RxOne Care?",
    answer:
      "RxOne Care is a comprehensive healthcare service provider dedicated to delivering high-quality medical treatments, personalized care, and support to patients both locally and internationally. They specialize in connecting patients with top medical professionals, facilitating medical tourism, and ensuring a seamless healthcare experience from consultation to recovery.",
  },
  {
    id: 1,
    question: "What services does RxOne Care offer?",
    answer:
      "RxOne Care offers a wide range of services including medical consultations, personalized treatment planning, medical tourism assistance, appointment scheduling, and post-treatment support.",
  },
  {
    id: 2,
    question: "How can I schedule a consultation with RxOne Care?",
    answer:
      "You can schedule a consultation with RxOne Care online at https://calendly.com/rxonecare, by calling their customer service team at +91-7703934446, or by sending an email to contact_us@rxone.app.",
  },
  {
    id: 3,
    question:
      "Do I need to pay extra for the services that RxOne Care provides?",
    answer:
      "*No!* The services provided by RxOne Care are completely free of cost for patients across all nations. In fact, we strive to negotiate with hospitals on your behalf to reduce the cost of treatment. No extra cost is ever borne by the patient.",
  },
  {
    id: 4,
    question: "Does RxOne Care assist with medical tourism?",
    answer:
      "Yes, RxOne Care specializes in medical tourism services. They assist international patients by coordinating travel arrangements, providing local support, ensuring quality care, and handling documentation.",
  },
  {
    id: 5,
    question:
      "What types of medical treatments are available through RxOne Care?",
    answer:
      "RxOne Care offers a diverse range of medical treatments, including cardiology, orthopedics, oncology, cosmetic surgery, fertility treatments, and dental care.",
  },
  {
    id: 6,
    question: "How does RxOne Care ensure the quality of its medical partners?",
    answer:
      "RxOne Care ensures the quality of its medical partners by verifying credentials, partnering with accredited hospitals, conducting continuous evaluations, and incorporating patient feedback for ongoing improvement.",
  },
  {
    id: 7,
    question:
      "How does RxOne Care handle patient confidentiality and data security?",
    answer:
      "Patient confidentiality and data security are paramount at RxOne Care. We implement robust security measures to protect personal and medical information, adhere to strict confidentiality policies in compliance with international regulations, use encrypted channels for all communications and data exchanges, and restrict access to sensitive information to authorized personnel only.",
  },
  {
    id: 8,
    question: "What should I expect during my consultation with RxOne Care?",
    answer:
      "During your consultation with RxOne Care, you can expect a comprehensive assessment of your medical history and current health status, the development of a personalized treatment plan, clear and transparent information about your options, procedures, and expected outcomes, support services for logistics, and a Q&A session to address any concerns or questions you may have.",
  },
  {
    id: 9,
    question:
      "Can RxOne Care assist with visa and travel documentation for medical tourism?",
    answer:
      "Yes, RxOne Care provides comprehensive assistance with all necessary visa and travel documentation for medical tourism. Our services include visa application support, providing appointment letters from medical facilities, travel itinerary planning, and arranging local transportation between the airport, hospital, and accommodation.",
  },
  {
    id: 10,
    question:
      "What is the process for post-treatment follow-up with RxOne Care?",
    answer:
      "Post-treatment follow-up with RxOne Care involves regular check-ins with scheduled follow-up appointments to monitor recovery progress, remote consultations for ongoing concerns, assistance with accessing rehabilitation services if needed, feedback collection to improve services, and continued care coordination to ensure seamless communication with local healthcare providers for ongoing care.",
  },
];
export const faqss = [
  {
    id: 0,
    question: "What are the benefits of booking pathology test online?",
    answer:
      "Booking a pathology test online offers convenience, time savings, easy comparison of test options, home sample collection, quicker report access, and transparent pricing, all from the comfort of your home.",
  },
  {
    id: 1,
    question: "When do I need to pay for pathology test I have ordered?",
    answer:
      "Payment for a pathology test is typically required either at the time of booking online or during sample collection, depending on the service provider's policy. Some may also allow payment upon receiving the test results.",
  },
  {
    id: 2,
    question: "When and how do I get the test report delivered?",
    answer:
      "Test reports are usually delivered within 24-48 hours after sample collection. They are sent via email, SMS, or made available for download through the provider's website or mobile app.",
  },
  {
    id: 3,
    question:
      "If I have questions with my test report, how can I consult with the doctor?",
    answer:
      "You can consult with a doctor by scheduling a teleconsultation through the provider's platform, contacting their support team, or using a dedicated helpline they offer for report-related queries. Some services may also provide access to doctors directly through their app.",
  },

  
];

export const faqsss = [
  {
    id: 0,
    question: "Is it ok to buy medicine online?",
    answer:
      "Yes, it's safe if you buy from a licensed, reputable pharmacy.",
  },
  {
    id: 1,
    question: "Are there any advantages of buying medicine online?",
    answer:
      "Yes, it offers convenience, home delivery, access to a wider range of products, and often lower prices or discounts.",
  },
  {
    id: 2,
    question: "Can I return the medicine bought online?",
    answer:
      "Return policies vary by platform, but many allow returns for unopened and undamaged medicines within a specific time frame.",
  },
  {
    id: 3,
    question:
      "How can I confirm that delivered medicines are genuine?",
    answer:
      "Ensure the pharmacy is licensed, check for proper packaging, verify batch numbers, and look for the manufacturer's seal.",
  },

  
];
export const Inter = [
  {
    id: 0,
    question: "Any special facilities and services that you provide international patients?",
    answer:
    <div>
    Airport pickup and drop<br />
    A SIM card, provided to you immediately after your arrival, so that you can talk to your relatives back home<br />
    VISA/FRRO assistance<br />
    Language interpreters<br />
    Foreign Exchange conversion at the hospital<br />
    Fax, photocopy, and courier services<br />
    Laundry services<br />
    In-room color television, cable connection, DVD player<br />
    Complimentary Wi-Fi in most of our hospitals<br />
    Complimentary stay in your room for one attendant (while you are being operated on, we will provide alternative accommodation for your attendant)<br />
    Special arrangements to meet specific religious or dietary needs<br />
    Daily updates for your referring doctors and family<br />
  </div>
  },
  {
    id: 1,
    question: "What about my doctor appointments?",
    answer:
      "Our International Patient Coordinator will liaise between you and the hospital. He will arrange your appointments as well as the referrals that will be needed for various services like the outpatient clinic, laboratory and pathology, medical imaging, and inpatient surgical procedures.",
  },
  {
    id: 2,
    question: "Where will I stay till I’m admitted to the hospital or after I’m discharged?",
    answer:
      "Depending on your budget, we can arrange accommodation for you and your attendant close to the hospital at nearby guest houses, service apartments or luxurious 5-star hotels. Facilities like hospital visits, on-call doctor, cab services and emergency ambulance can also be arranged by us.",
  },
  {
    id: 3,
    question:
      "Will you arrange for an airport pickup?",
    answer:
      "Sure. Before your arrival, our International Patients Services Team will send you the name and contact details of the staffer being sent to meet you. This person will escort you to the hospital or accommodation. If you are unable to find the person sent to pick you up, all you need to do is call our hospital helpline.",
  },
  {
    id: 4,
    question: "Can you help me get a visa? ",
    answer:
    <div>
    Certainly. Once you confirm your visit to our facility for medical treatment, we will send a visa requisition letter to the Indian Embassy in your country. A copy of the letter will be marked to you. Please send us the following details via email if you want us to send a visa requisition letter to the Indian Embassy in your country:<br /><br />
    
    ⦁ Patient's name and passport number<br />
    ⦁ Medical escort's name and passport number<br />
    ⦁ Country<br />
    ⦁ Your tentative date of arrival at Fortis for treatment<br /><br />
    
    Note:<br />
    ⦁ If you are traveling on a medical visa, you are required to register at the Foreigner’s Regional Registration Office within 14 days of your arrival. Our team will help you with all these formalities.<br />
    ⦁ Patients from Pakistan and Bangladesh are required to register with the nearest Police Station or Police Commissioner’s office within 24 hours of arrival. Refer to your Visa/Transit document for instructions.<br />
    ⦁ Please bring 10 passport size photographs with you for the above formalities.<br />
  </div>
  },
  {
    id: 5,
    question: "How does RxOne Healthcare compare with other Healthcare companies? ",
    answer:
    <div>
    ⦁ A large network of integrated healthcare services across Asia-Pacific<br />
    ⦁ World-class medical facilities that use the latest technologies<br />
    ⦁ A team of highly specialized, internationally trained doctors who are globally renowned for their expertise and skills<br />
    ⦁ Seamless services and personalized care and attention<br />
    ⦁ Great value for money<br />
  </div>
  },
  {
    id: 6,
    question: "How does India compare with other countries for medical treatment? ",
    answer:
      "India has some of the world's best healthcare facilities, staffed by doctors with international qualifications & vast and varied experience. The cost of treatment is substantially lesser than in developed countries, in some cases the difference can result in as much as 60% savings. For patients, the result is a win-win situation, world class medical attention and patient care services and savings too.",
  },
  
];
export const medi = [
  {
    id: 0,
    question: "What are the medical loans?",
    answer:
      "In a time of medical emergency, a medical loan supports getting the treatment done when someone is struggling due to lack of finances. A medical loan can be considered as a personal loan. With a personal medical loan, RxOne assured you about money for medical care when you need it the most. The medical loan doesn't demand any surety or security however, one needs to repay the EMI's on time to time. Thus, paying for your hospital or Medicare bills, o surgical procedures, and optional surgical procedures, and other medically-associated costs through a personal loan when you are not medically covered, is considered as a medical loan",
  },
  {
    id: 1,
    question: "What are the benefits of an online medical loan?",
    answer: `
      A medical crisis is unpredictable and often causes stress, especially when facing financial instability. Medical loan services help reduce this stress by providing financial support for surgeries, treatments, or other medical expenses.
      
      - **Easy to apply**: Applying for an online medical loan is simple. Fill in details like name, mobile number, city, hospital name, and loan amount.
      
      - **Quick approval & disbursal**: After submitting the request, you get instant approval, and the loan disbursal is fast, making it a convenient cashless process.
      
      - **Loan for any treatment**: Medical loans can be used for any medical need, including emergencies like surgery, daycare procedures, therapies, or even dental treatments.
      
      - **No collateral required**: These are personal loans, so you don't need to provide collateral or a security deposit.
      
      - **Flexible tenure options**: Loan tenures range from 6 to 60 months, depending on the amount, with options to extend if needed.
      
      - **Minimal documentation**: The application process is hassle-free and requires minimal paperwork.
    `,
  },  
  {
    id: 2,
    question: "Where to get medical loans in India?",
    answer:
      "Facing a financial crunch during any medical emergency, you need not fret as RxOne have the best solution for the problem RxOne is a quick online loan provider platform offering personal loans (Medical Loan) to cover medica expenses with easy EMIs. RxOne assures you to provide a medical loan without any additional formalities where you can move ahead with your medical treatment Starting from 20k, you can avail medical loan up to 52, which you ca pay in a tenure of 6 months to 36 months. Moreover an attractive interest rate of 0-91% is available with all partner hospitals However, with the non-partner available interest rate is 12-18% One can simply apply for a medical loan through RxOne'swebsite, after approval we will disburse the funds quickly",
  },


  
];
export const hip = [
  {
    id: 0,
    question: "Is the belt comfortable?",
    answer:
      "Our belt is specifically tailored for older adults aged 65 and above who experience fear of falling or limit their activities due to concerns about falling. Developed in collaboration with residents in long-term care and post-acute facilities, our product focuses on form, fit, and features.",
  },
  {
    id: 1,
    question: "Does the belt come in different sizes?",
    answer:
      "Our product offers four sizes that accommodate the 5th to 95th percentile of the Indian older adult population. Each size allows for adjustments to ensure an optimal fit.",
  },
  {
    id: 2,
    question: "Is the belt showerproof?",
    answer:
      "Our belt is splash-resistant, but it should not be submerged or used while showering.",
  },
  {
    id: 3,
    question: "Is the belt reusable?",
    answer:
      "Yes, the belt remains usable even after a fall, and it comes with nearly zero recurring costs.",
  },
  {
    id: 4,
    question: "Is the belt safe?",
    answer:
      "Yes, the belt is not dangerous. We use only BIS-marked electronics, maintaining top standards and quality.",
  },
];