import React from "react";
import { NavLink } from "react-router-dom";

const OfferModal = ({ images, currentImageIndex, handleregis, handleClose }) => {
  return (
    <div className="modal-overlay2">
      <div className="modal-content2">
        {/* Close Button */}
        <button className="modal-close-btn2" onClick={handleClose}>
          ✕
        </button>

        {/* Modal Content */}
        <div className="modal-header2">
          <h5>
            LIMITED TIME OFFER! Get{" "}
            <span style={{ color: "rgb(255, 203, 37)" }}>FREE</span> treatment
            advice from our experts
          </h5>
        </div>

        {/* Consult Now Button */}
        <NavLink to="https://rxone.app/treatment-advice" target="_blank">
          <button className="modal-consult-btn2">
            <span style={{ fontSize: "17px" }}>CONSULT NOW</span>
          </button>
        </NavLink>

        {/* Ad Images */}
        <div className="modal-images2">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Ad ${index + 1}`}
              className={`modal-image2 ${index === currentImageIndex ? "active" : ""}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OfferModal;
