import React from "react";
import { useState, useEffect } from "react";
import ServiceCall from "../ServiceCall";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../Css/doctoreprofilee.css";
import doctorImage from "../Images/11.png"; // Replace with your image path
import rxv from "../Images/home/RxOne_Verified.png";
import LanguageIcon from "@mui/icons-material/Language";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import EarbudsIcon from "@mui/icons-material/Earbuds";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import { Helmet } from "react-helmet";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { countriesList } from "../utility/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import backgroundimg from "../Images/banner-bg.jpg";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Modal, Button } from "react-bootstrap";
import CenteredAlert from "./CenteredAlert";
import { useMediaQuery } from "@material-ui/core";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  TextField,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";
import emailjs from "emailjs-com";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
  salutationFormControl: {
    width: "27%", // Adjust the width of the salutation select
  },
  gender: {
    width: "30%",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  nameField: {
    background: "white",
  },
  select: {
    border: "none", // Remove border
    width: "50px", // Adjust the width as needed
  },
}));
const Doctoreprofilee = () => {
  const mode = process.env.REACT_APP_MODE;
  const [doctordata, setDoctordata] = useState("");
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const { route_key } = useParams();
  const patientportal = process.env.REACT_APP_PATIENTPORTAL;
  const book_Appointment = process.env.REACT_APP_BOOK_WIDGET;
  const [showModal, setShowModal] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [showModals, setShowModals] = useState(false);
  const classes = useStyles();
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [message, setMessage] = useState("");
  const isMobile = useMediaQuery("(max-width:710px)");
  const [name, setName] = useState("");
  const [pin, setPin] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate("");
  const location = useLocation();
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Days of the week (static array)
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  // Toggle tooltip visibility on click for mobile
  const handleTooltipToggle = () => {
    setTooltipVisible((prev) => !prev);
  };
  const handlePrevious = () => {
    setCurrentDayIndex((prev) => (prev === 0 ? days.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentDayIndex((prev) => (prev === days.length - 1 ? 0 : prev + 1));
  };

  // Get the current day and schedule
  const currentDay = days[currentDayIndex];
  const scheduleForDay = doctordata?.schedule_cards?.filter((schedule) =>
    schedule.days?.includes(currentDay)
  );

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );

  const LoadingOverlay = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <CircularProgress className="text-white" />
    </div>
  );
  const handleWhatsAppSubmit = (e) => {
    e.preventDefault();
    if (!phone || phone.length < 10) {
      setPhoneError(true);
      return;
    }
    setPhoneError(false);
    setShowWhatsAppModal(false);
    // Handle form submission logic here
    console.log({ name, phone: `${phoneCode}${phone}`, message });
  };
  const showAlerts = () => {
    setShowAlert(true);
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setMessage("");
    setPhone("");
  };

  const handlesubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const serviceid = "service_7yxtqsn";
    const templateid = "template_x3wusd7";
    const publickey = "4UpyJJj3P5JGeMZFz";
    const messageContent = `
      Type: 'Appointment Booking For- ${doctordata?.doctor_card?.doctor_name}'
      Patient Name: ${name}
      Phone: ${phoneCode}${phone}
      Problem: ${message}
    `;

    const templateparams = {
      from_name: name,
      from_email: email,
      to_name: "web",
      message: messageContent,
    };

    emailjs
      .send(serviceid, templateid, templateparams, publickey)
      .then((response) => {
        setIsLoading(false);
        console.log("email sent successfully", response);
        showAlerts();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error sending email", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePhoneCodeChange = (event) => {
    setPhoneCode(event.target.value);
  };
  useEffect(() => {
    const doctorprofile = async () => {
      try {
        // Show loader before fetching
        setShowLoader(true);

        // Fetch doctor profile data
        const profiledata = await ServiceCall.get(
          `/v2/patient/fetch/open/doctor/profile/${mode}/${route_key}`
        );

        // If data is fetched successfully, set the data in the state
        if (profiledata) {
          setDoctordata(profiledata.data);
        }
      } catch (err) {
        console.error("Error fetching doctor data:", err);
      } finally {
        // Hide the loader only when both data is fetched and set
        setShowLoader(false); // Hide loader
        setLoadingData(false); // Mark data as fully loaded
      }
    };

    doctorprofile(); // Call the function immediately on mount
  }, []);
  // Empty dependency array ensures this effect runs only once, on mount
  const toggleQuestion = (id) => {
    if (expandedQuestions.includes(id)) {
      setExpandedQuestions(expandedQuestions.filter((qid) => qid !== id));
    } else {
      setExpandedQuestions([...expandedQuestions, id]);
    }
  };
  const handleID = (res, event) => {
    console.log(333, res);
    // setDoctorID(res.doc_id);
    // setHospitalID(res.hos_id);
    // setDoctorDetails(res);
    // setPerson(event);
    if (res.show_booking_button === false) {
      setShowModals(true);
    } else if (res.show_booking_button === true) {
      const url = `${book_Appointment}${route_key}`;
      setIframeUrl(url);
      setShowModal(true);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false); // Hide loader after 2 seconds
    }, 4000);

    return () => clearTimeout(timer); // Clear timeout on component unmount
  }, []);

  const unicode = "\u20B9";

  const titledata = doctordata.meta_description;
  const handlehos = () => {
    if (doctordata?.hospital_route_key) {
      navigate(`/hospitalpage/${doctordata?.hospital_route_key}`);
    }
  };
  return (
    <>
      {loadingData ? (
        <Loader /> // Show loader while loading
      ) : (
        <>
          {showLoader && <LoadingOverlay />}

          <div className="doctoreprofilee-container">
            <header
              className="doctoreprofilee-header"
              style={{
                fontFamily: " Tahoma, sans-serif",
                fontWeight: "bolder",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                  fontFamily: " Tahoma, sans-serif",
                  color: "white",
                  marginBottom: "30px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Capriola",
                    fontSize: isMobile ? "2rem" : "3.5rem", // Adjust font size based on isMobile
                    borderRadius: "3%",
                  }}
                >
                  {doctordata?.doctor_card?.doctor_name || "Name not available"}
                </p>
              </div>
            </header>
            <div className="doctoreprofilee-content">
              {/* Left Section */}
              <div className="left-section4">
                <div className="fixed-box">
                  <div className="doctoreprofilee-container">
                    <div className="doctor-image3">
                      <img
                        src={
                          doctordata?.doctor_card?.doctor_profile_pic ||
                          ServiceCall.images(
                            `v2/patient/doctor/pic/route/key/${mode}/${route_key}`
                          )
                        }
                        alt={doctordata?.doctor_card?.doctor_name || "Doctor"}
                        onError={(e) =>
                          (e.target.src = "/path/to/placeholder/image.jpg")
                        } // Fallback if image fails to load
                        className="doctor-profile-image"
                      />
                      <img
                        src={rxv} // Logo image
                        alt="Logo"
                        className="doctor-logo"
                      />
                      {doctordata?.doctor_card?.online_discount_stmt !==
                        "Get upto 0% discount on advance booking!" && (
                        <div className="discount">
                          <h5>
                            <strong>
                              {doctordata?.doctor_card?.online_discount_stmt}
                            </strong>
                          </h5>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="fixed-box" style={{ marginTop: "-50px" }}>
                  <p className="doctor-name">
                    {doctordata?.doctor_card?.doctor_name ||
                      "Name not available"}
                  </p>
                  <p className="doctor-speciality">
                    {doctordata?.doctor_card?.speciality ||
                      "Speciality not available"}
                  </p>
                  <p className="doctor-experience">
                    {doctordata?.doctor_card?.experience
                      ? `${doctordata.doctor_card.experience} years Experience`
                      : "Experience not available"}
                  </p>

                  <div
                    className="google-ratings-container"
                    style={{
                      textAlign: "center",
                      margin: "10px 0",
                      position: "relative", // Tooltip positioning
                    }}
                    onClick={handleTooltipToggle} // Handle click for mobile
                  >
                    <div
                      className={`google-ratings-tooltip ${
                        tooltipVisible ? "visible" : ""
                      }`}
                    >
                      This rating is based on google
                    </div>
                    <div className="google-rating-stars">
                      {Array.from({
                        length: Math.floor(
                          doctordata?.doctor_card?.google_rating || 0
                        ),
                      }).map((_, i) => (
                        <i key={i} className="fa fa-star star-filled"></i>
                      ))}
                      {doctordata?.doctor_card?.google_rating % 1 !== 0 && (
                        <i className="fa fa-star-half-alt star-half"></i>
                      )}
                    </div>
                  </div>

                  <div className="parent-container">
                    <button
                      className="tele__btn ms-border-rounded ms-btn"
                      type="button"
                      onClick={() => handleID(doctordata, "True", "Clinic")}
                    >
                      <a style={{ color: "white", textDecoration: "none" }}>
                        Book Appointment
                      </a>
                    </button>
                  </div>
                </div>

                {doctordata?.schedule_cards?.length > 0 && (
                  <div className="schedule-slider">
                    {doctordata.schedule_cards.map((schedule, index) => (
                      <div key={index}>
                        {/* Slider Header */}
                        <div className="slider-header">
                          <button
                            className="slider-btn"
                            onClick={handlePrevious}
                          >
                            &lt;
                          </button>
                          <h3>{schedule.days[currentDayIndex]}</h3>{" "}
                          {/* Display Current Day */}
                          <button className="slider-btn" onClick={handleNext}>
                            &gt;
                          </button>
                        </div>

                        {/* Calendar Box */}
                        <div className="calendar-box">
                          <div className="schedule-card">
                            {/* Title Section */}
                            <div className="title-section">
                              {schedule.title === "VIDEO CONSULTATION" && (
                                <VideoLabelIcon
                                  style={{ marginRight: "4px" }}
                                />
                              )}
                              {schedule.title === "IN-CLINIC VISITS" && (
                                <LocalHospitalIcon />
                              )}
                              <h5>
                                {schedule.title
                                  ?.toLowerCase()
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                              </h5>
                            </div>

                            {/* Morning and Evening Sections */}
                            <div className="time-sections">
                              <div className="morning-section">
                                <h4>Morning to Evening</h4>
                                <div className="time-slot">
                                  <p>
                                    <ScheduleOutlinedIcon />{" "}
                                    {schedule.time.start_time} -{" "}
                                    {schedule.time.end_time}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {doctordata?.body_cards?.[0]?.object?.length > 0 && (
                  <div className="fixed-box">
                    <h5 className="mb-1">
                      <LanguageIcon style={{ margin: "10px" }} />
                      {doctordata.body_cards[0].heading}
                    </h5>
                    <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                      {doctordata.body_cards[0].object.map((lang, index) => (
                        <li key={index}>
                          {lang?.title} <br />
                          {lang?.description && (
                            <span style={{ color: "gray" }}>
                              ({lang?.description})
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {/* Right Section */}
              <div className="right-section4">
                {doctordata?.body_cards?.[2]?.object?.length > 0 && (
                  <div className="languages card">
                    <h5
                      className="mb-1"
                      style={{
                        fontSize: isMobile ? "14px" : "16px", // Adjust font size for mobile
                        textAlign: isMobile ? "center" : "left", // Center the title on mobile
                        marginBottom: isMobile ? "5px" : "10px", // Reduce margin below title on mobile
                      }}
                    >
                      <PersonIcon style={{ margin: "10px" }} />
                      {doctordata?.body_cards?.[2]?.heading}
                    </h5>
                    <ul
                      style={{
                        listStyleType: "none",
                        paddingLeft: "0",
                        textAlign: isMobile ? "center" : "left", // Center-align the list on mobile
                        marginBottom: isMobile ? "0" : "15px", // Remove margin at the bottom of the list for mobile
                      }}
                    >
                      {doctordata?.body_cards?.[2]?.object.map(
                        (about, index) => (
                          <p
                            key={index}
                            style={{
                              fontSize: isMobile ? "12px" : "14px", // Smaller font size on mobile
                              marginBottom: isMobile ? "2px" : "5px", // Reduce space between title and description on mobile
                            }}
                          >
                            {about?.title}
                            {about?.description && (
                              <span style={{ color: "gray" }}>
                                {about?.description}
                              </span>
                            )}
                          </p>
                        )
                      )}
                    </ul>
                  </div>
                )}

                <div className="languages card">
                  {doctordata?.body_cards?.[2]?.object?.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: isMobile ? "center" : "flex-start", // Center align on mobile
                        flexDirection: isMobile ? "column" : "row", // Stack vertically on mobile
                        textAlign: isMobile ? "center" : "left", // Center text on mobile
                      }}
                      className="col-lg-8"
                    >
                      <div
                        style={{
                          display: "inline-block",
                        }}
                        onClick={handlehos}
                      >
                        <h6
                          style={{
                            fontWeight: "500",
                            fontSize: isMobile ? "14px" : "17px", // Smaller font on mobile
                            color: "black",
                            cursor: doctordata?.hospital_route_key
                              ? "pointer"
                              : "",
                          }}
                        >
                          <LocalHospitalIcon />
                          {doctordata?.doctor_card?.hospital
                            ? doctordata.doctor_card.hospital.toUpperCase()
                            : "Hospital Name Not Available"}
                        </h6>
                        <h6
                          style={{
                            fontWeight: "500",
                            fontSize: isMobile ? "12px" : "14px",
                            color: "grey", // Smaller font on mobile
                          }}
                        >
                          <AddLocationIcon />
                          {doctordata?.doctor_card?.address.toUpperCase()},{" "}
                          {doctordata?.doctor_card?.city}
                        </h6>
                      </div>
                    </div>
                  )}
                </div>

                {doctordata?.body_cards?.[1]?.object?.length > 0 && (
                  <div className="languages card">
                    <h5
                      className="mb-1"
                      style={{
                        fontSize: isMobile ? "14px" : "16px", // Adjust font size for mobile
                        textAlign: isMobile ? "center" : "left", // Center the text on mobile
                      }}
                    >
                      <SchoolIcon style={{ margin: "10px" }} />
                      {doctordata?.body_cards?.[1]?.heading}
                    </h5>
                    <ul
                      style={{
                        listStyleType: "none",
                        paddingLeft: "0",
                        textAlign: isMobile ? "center" : "left", // Center-align list items on mobile
                      }}
                    >
                      {doctordata?.body_cards?.[1]?.object.map(
                        (experience, index) => (
                          <li
                            key={index}
                            style={{
                              fontSize: isMobile ? "13px" : "14px",
                              color: "grey", // Smaller font size on mobile
                            }}
                          >
                            {experience?.title} <br />
                            {experience?.description && (
                              <span style={{ color: "gray" }}>
                                ({experience?.description})
                              </span>
                            )}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
                {doctordata?.body_cards?.[5]?.object?.length > 0 && (
                  <div className="languages card">
                    <h5
                      className="mb-1"
                      style={{
                        fontSize: isMobile ? "14px" : "16px", // Adjust font size for mobile
                        textAlign: isMobile ? "center" : "left", // Center the text on mobile
                      }}
                    >
                      <CastForEducationIcon style={{ margin: "10px" }} />
                      {doctordata?.body_cards?.[5]?.heading}
                    </h5>
                    <ul
                      style={{
                        listStyleType: "none",
                        paddingLeft: "0",
                        textAlign: isMobile ? "center" : "left", // Center-align list items on mobile
                      }}
                    >
                      {doctordata?.body_cards?.[5]?.object.map(
                        (qualification, index) => (
                          <li
                            key={index}
                            style={{
                              fontSize: isMobile ? "13px" : "14px",
                              color: "grey", // Smaller font size on mobile
                            }}
                          >
                            {qualification?.title} <br />
                            {qualification?.description && (
                              <span style={{ color: "gray" }}>
                                ({qualification?.description})
                              </span>
                            )}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}

                {doctordata?.body_cards?.[4]?.object?.length > 0 && (
                  <div className="languages card">
                    <h5
                      className="mb-1"
                      style={{
                        fontSize: isMobile ? "14px" : "16px", // Adjust font size for mobile
                        textAlign: isMobile ? "center" : "left", // Center the text on mobile
                      }}
                    >
                      <HowToRegIcon style={{ margin: "10px" }} />
                      {doctordata?.body_cards?.[4]?.heading}
                    </h5>
                    <ul
                      style={{
                        listStyleType: "none",
                        paddingLeft: "0",
                        textAlign: isMobile ? "center" : "left", // Center-align list items on mobile
                      }}
                    >
                      {doctordata?.body_cards?.[4]?.object.map(
                        (registration, index) => (
                          <li
                            key={index}
                            style={{
                              fontSize: isMobile ? "12px" : "14px",
                              // Smaller font size on mobile
                            }}
                          >
                            {registration.title} <br />
                            {registration.description && (
                              <span style={{ color: "gray" }}>
                                ({registration.description})
                              </span>
                            )}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}

                {doctordata?.body_cards?.[3]?.object?.length > 0 && (
                  <div className="languages card">
                    <h5
                      className="mb-1"
                      style={{
                        fontSize: isMobile ? "14px" : "16px", // Adjust font size for mobile
                        textAlign: isMobile ? "center" : "left",
                        // Center the title on mobile
                      }}
                    >
                      <EarbudsIcon style={{ margin: "10px" }} />
                      {doctordata?.body_cards?.[3]?.heading}
                    </h5>
                    <ul
                      style={{
                        listStyleType: "none",
                        paddingLeft: "0",
                        textAlign: isMobile ? "center" : "left", // Center-align the list on mobile
                      }}
                    >
                      {doctordata?.body_cards?.[3]?.object.map(
                        (specialization, index) => (
                          <li
                            key={index}
                            style={{
                              fontSize: isMobile ? "12px" : "14px",
                              // Smaller font size on mobile
                            }}
                          >
                            {specialization.title} <br />
                            {specialization.description && (
                              <span style={{ color: "gray" }}>
                                ({specialization.description})
                              </span>
                            )}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <h5
            className="mb-4 ms-font-weight-600 ms-primary-color"
            style={{ marginLeft: "1rem", fontSize: "1.4rem" }}
          >
            Frequently Asked Questions
          </h5>

          <ul style={{ paddingLeft: "0rem" }}>
            {doctordata.faqs &&
            doctordata.faqs.object &&
            doctordata.faqs.object.length > 0 ? (
              doctordata.faqs.object.map((faq) => (
                <li className="languages card" key={faq.id}>
                  <div>
                    <strong>{faq.question}</strong>
                    <button
                      onClick={() => toggleQuestion(faq.id)}
                      style={{
                        border: "none",
                        background: "none",
                        display: "flex",
                        justifyContent: "end", // Aligns content to the end
                        width: "100%", // Ensures the icon is at the end even if the button size changes
                        marginTop: "-22px",
                      }}
                    >
                      <span>
                        {expandedQuestions.includes(faq.id) ? "▲" : "▼"}
                      </span>
                    </button>
                  </div>
                  {/* Render answer only if question is expanded */}
                  {expandedQuestions.includes(faq.id) && <p>{faq.answer}</p>}
                </li>
              ))
            ) : (
              <p>No FAQs available.</p>
            )}
          </ul>

          {showAlert && (
            <CenteredAlert
              message="We have received your request, please allow us up-to 24 Hrs. to come back to you."
              onClose={() => setShowAlert(false)}
            />
          )}

          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // style={{width: "35%", left: "30%"}}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Book Appointment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe
                src={iframeUrl}
                width="100%"
                height="650px"
                title="Appointment Booking"
                frameBorder="0"
                allowFullScreen
              />
            </Modal.Body>
            {/* <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer> */}
          </Modal>

          <Modal
            show={showModals}
            onHide={() => setShowModals(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // style={{width: "35%", left: "30%"}}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.4rem",
                fontWeight: "600",
                marginBottom: "1rem",
                color: "#005c74",
              }}
            >
              Book Appointment
            </span>

            <h1
              style={{
                fontSize: "1.4rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              We will call you to confirm your booking with the{" "}
              {doctordata?.doctor_card?.doctor_name}
            </h1>
            <Modal.Body>
              <form style={{ textAlign: "center" }} onSubmit={handlesubmit}>
                <TextField
                  id="firstName"
                  name="first_name"
                  label="Full Name"
                  variant="filled"
                  style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  fullWidth
                  className={classes.nameField}
                />
                <FormControl
                  variant="filled"
                  fullWidth
                  className={classes.nameField}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="w-23"
                      onChange={handlePhoneCodeChange}
                      value={phoneCode} // Concatenate phoneCode and phone here
                      classes={{ select: classes.select }}
                    >
                      {countriesList &&
                        countriesList.map((item, index) => (
                          <MenuItem
                            value={item.code}
                            key={item.code + "-" + index}
                          >
                            {item.code}
                          </MenuItem>
                        ))}
                    </Select>

                    <InputLabel
                      htmlFor="phoneNumber"
                      style={{ marginLeft: "101px" }}
                    >
                      Phone Number*
                    </InputLabel>
                    <Input
                      id="phoneNumber"
                      name="phone"
                      label="Phone Number"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      error={phoneError}
                      style={{
                        flex: 1,
                        marginLeft: 6,
                        width: "100px",
                        marginBottom: "1rem",
                      }} // Adjust the margin and flex properties
                      className={classes.nameField}
                    />
                  </div>
                  {phoneError && (
                    <FormHelperText error>
                      Please enter a valid phone number.
                    </FormHelperText>
                  )}
                </FormControl>

                <TextField
                  id="chiefComplaints"
                  name="message"
                  label="Decribe Your Medical Problem"
                  variant="filled"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  fullWidth
                  style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                  className={classes.nameField}
                />

                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                  style={{ position: "relative" }}
                >
                  {isLoading ? (
                    <>
                      <CircularProgress
                        size={24}
                        style={{
                          color: "blue",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                      <span style={{ visibility: "hidden" }}>Submit</span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </form>
            </Modal.Body>
          </Modal>

          <div
            className="calendar-icon-block"
            style={{
              position: "fixed", // Ensures it stays fixed in place
              right: "8px", // Distance from the right edge
              bottom: "91px", // Distance from the bottom edge
              zIndex: 1000, // Ensures it stays on top of other content
              cursor: "pointer",
              backgroundColor: "#01A09E", // Optional: Add background for better visibility
              borderRadius: "50%", // Optional: Make it circular
              padding: "10px", // Optional: Add some padding
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional: Add shadow
            }}
            onClick={() => setShowWhatsAppModal(true)}
          >
            <CalendarMonthIcon
              style={{
                fontSize: "40px", // Adjust icon size
                color: "white", // Customize icon color
              }}
            />
          </div>

          {/* Modal */}
          <Modal
            show={showWhatsAppModal}
            onHide={() => setShowWhatsAppModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Book Appointment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form
                style={{ textAlign: "center" }}
                onSubmit={handleWhatsAppSubmit}
              >
                <TextField
                  id="fullName"
                  name="full_name"
                  label="Full Name"
                  variant="filled"
                  style={{
                    marginBottom: "20px",
                    backgroundColor: "white",
                  }}
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
                <FormControl
                  variant="filled"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Select
                      labelId="phone-code-select-label"
                      id="phone-code-select"
                      value={phoneCode}
                      onChange={(e) => setPhoneCode(e.target.value)}
                      style={{ marginRight: "10px", width: "80px" }}
                    >
                      {countriesList.map((item) => (
                        <MenuItem value={item.code} key={item.code}>
                          {item.code}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      id="phoneNumber"
                      name="phone"
                      label="Phone Number"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      error={phoneError}
                      style={{ flex: 1 }}
                    />
                  </div>
                  {phoneError && (
                    <FormHelperText error>
                      Please enter a valid phone number.
                    </FormHelperText>
                  )}
                </FormControl>
                <TextField
                  id="message"
                  name="message"
                  label="Describe Your Medical Problem"
                  variant="filled"
                  style={{
                    marginBottom: "20px",
                    backgroundColor: "white",
                  }}
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Submit
                </Button>
              </form>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default Doctoreprofilee;
