import React, { useEffect, useState, useContext, useRef } from "react";
import "../Css/booking2.css";
import doctorTemplate from "../Images/doctor-template.png";
import "aos/dist/aos.css";
import AOS from "aos";
import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import ServiceCall from "../ServiceCall";
import AppointmentScreen from "./AppointmentScreen";
import { Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Doctorprofile from "./Doctorprofile";
import Doctoreprofilee from "./Doctoreprofilee";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import GradingIcon from "@mui/icons-material/Grading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { useMediaQuery } from "@material-ui/core";
import CenteredAlert from "./CenteredAlert";
import mapphoto from "../Images/home/map.jpg";
import modernMedicineLogo from "../Images/home/Allopathy.png";
import dentistryLogo from "../Images/home/Dentistry.png";
import physiotherapyLogo from "../Images/home/Physiotherapy.png";
import homeopathyLogo from "../Images/home/Homeopathy.png";
import ayurvedaLogo from "../Images/home/Ayurveda.png";
import yogaMeditationLogo from "../Images/home/Yoga.png";
import unaniLogo from "../Images/home/Unani.png";
import nutritionDietLogo from "../Images/home/Naturopathy.png";
import mentalHealthLogo from "../Images/home/Mentalhealth.png";
import ad1 from "../Images/home/ad4.jpg";
import ad2 from "../Images/home/ad5.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import verified from "../Images/home/RxOne_Verified.png";
import verified2 from "../Images/home/rxone-v.png";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Link } from "@mui/material";
import CallMadeIcon from "@mui/icons-material/CallMade";
import {
  TextField,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";
import { countriesList } from "../utility/constants";
import emailjs from "emailjs-com";
import whatsapp from "../Images/whatsapp.png";
import OfferModal from "./OfferModal";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
  salutationFormControl: {
    width: "27%", // Adjust the width of the salutation select
  },
  gender: {
    width: "30%",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  nameField: {
    background: "white",
  },
  select: {
    border: "none", // Remove border
    width: "50px", // Adjust the width as needed
  },
}));
const Booking2 = () => {
  const [show, setShow] = useState(false);
  const mode = process.env.REACT_APP_MODE;
  const book_Appointment = process.env.REACT_APP_BOOK_WIDGET;
  const patientportal = process.env.REACT_APP_PATIENTPORTAL;
  const [details, setDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [doctorID, setDoctorID] = useState("");
  const [hospitalID, setHospitalID] = useState("");
  const [doctorDetails, setDoctorDetails] = useState("");
  const [doctorDetail, setDoctorDetail] = useState("");
  const [showAppointment, setShowAppointment] = useState(false);
  const [showPerson, setPerson] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const { route_key } = useParams();
  const [iframeUrl, setIframeUrl] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [limit] = useState(10); // Number of doctors per page
  const [hasMore, setHasMore] = useState(true); // Whether there are more doctors to load
  const [filteredCount, setFilteredCount] = useState(0); // State for filtered_rows_count
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const navigate = useNavigate();
  const { code } = useParams();
  const [imageLoading, setImageLoading] = useState(true);
  const location = useLocation(); // Access location state
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const appMode = process.env.REACT_APP_MODE || "development";
  const [totalCount, setTotalCount] = useState(0);
  const [totalCounts, setTotalCounts] = useState(0);
  const [pageMarking, setPageMarking] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  console.log(233455, totalCounts, pageMarking, totalCount);
  // Initialize with default value
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const [selectedExperience, setSelectedExperience] = useState("");

  const handleExperienceChange = (event) => {
    setSelectedExperience(event.target.value);
  };
  const [selectedRating, setSelectedRating] = useState("");

  const handleRatingChange = (event) => {
    setSelectedRating(event.target.value);
  };
  const handleCloseSection = () => {
    // Logic to close the advertisement section
    const section = document.querySelector(".add-section3");
    section.style.display = "none";
  };
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const toggleFilter = () => {
    setIsFilterOpen((prev) => !prev);
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
    // fetchDoctors(true); // Reset pagination when searching
  };
  const [isMobileOfferVisible, setIsMobileOfferVisible] = useState(false);

  const toggleMobileOfferVisibility = () => {
    setIsMobileOfferVisible(!isMobileOfferVisible);
  };
  const departmentLogos = {
    "MODERN MEDICINE": modernMedicineLogo,
    DENTISTRY: dentistryLogo,
    PHYSIOTHERAPY: physiotherapyLogo,
    HOMEOPATHY: homeopathyLogo,
    AYURVEDA: ayurvedaLogo,
    "YOGA & MEDITATION": yogaMeditationLogo,
    UNANI: unaniLogo,
    "NUTRITION & DIET": nutritionDietLogo, // Corrected key with quotes
    "MENTAL HEALTH": mentalHealthLogo,
  };
  const [activeLogo, setActiveLogo] = useState(null);

  const handleLogoClick = async (department) => {
    setActiveLogo(department); // Set active logo state (highlight the clicked logo)
    setSelectedFilter(department); // Set selected filter based on clicked logo
    clearFilters();
    // Fetch data based on the selected department (logo clicked)
    fetchDepartmentData(department);
  };
  const fetchDepartmentData = async (selectedCode) => {
    setPage(1); // Reset to the first page when a new logo is clicked
    setDetails([]);
    setHasMore(true);
    setLoading(true);
    setSearchResults(null);
    setSearch([]);
    try {
      const body = {
        city: selectedCity,
        experince: parseInt(selectedExperience) || 0,
        rating: parseInt(selectedRating) || 0,
        hospital: selectedHospital,
      };

      const response = await ServiceCall.post(
        `v2/patient/open/pagination/filter/list/available/doctor/${mode}/${page}/${limit}?som=${selectedCode}`,
        body
      );
      setLoading(false);

      const newDoctors = response.data.doctors;
      const pageMarkingValue = response.data.page_marking;

      if (newDoctors.length < limit) {
        setHasMore(false); // If no more doctors are available, disable pagination
      }

      setDetails(newDoctors);
      setPageMarking(pageMarkingValue || "No Matches Found"); // Update pageMarking
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
      setPageMarking("Error fetching data");
    }
  };

  const handlePagination = async () => {
    try {
      setLoading(true); // Start the loader
      const res = await ServiceCall.get(
        `v2/patient/open/pagination/filter/list/available/doctor/${mode}/${page}/${limit}`
      );
      if (res) {
        const newDoctors = res.data.doctors;
        if (newDoctors.length > 0) {
          setDetails((prevDetails) => [...prevDetails, ...newDoctors]); // Append new doctors
          setHasMore(true); // More doctors to load
        } else {
          setHasMore(false); // No more doctors to load
        }
      }
    } catch (err) {
      console.error(err); // Handle errors appropriately
    } finally {
      setLoading(false); // Stop the loader
    }
  };

  const [callBackFun, setCallBackFun] = useState("");
  const [lookup, setLookup] = useState([]); // State to store fetched records
  const [selectedFilter, setSelectedFilter] = useState(""); // const [search, setSearch] = useState("");

  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [pin, setPin] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [docname, setDocname] = useState("");
  const isValidPhoneNumber = (phoneNumber) => {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };
  const [searchResults, setSearchResults] = useState(null);
  const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false); // Add this state to track if filters are applied

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);
  const toggleMobileFilterVisibility = () => {
    setIsMobileFilterVisible(!isMobileFilterVisible);
  };

  const showAlerts = () => {
    setShowAlert(true);
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setMessage("");
    setPhone("");
    setShowModals(false);
  };
  const [hospitals, setHospitals] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState("");
  const [noDoctorsFound, setNoDoctorsFound] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleHospitalChange = (e) => {
    setSelectedHospital(e.target.value);
  };
  const handlesubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const serviceid = "service_7yxtqsn";
    const templateid = "template_x3wusd7";
    const publickey = "4UpyJJj3P5JGeMZFz";
    const messageContent = `
      Type: 'Appointment Booking For - ${docname}'
      Patient Name: ${name}
      Phone: ${phoneCode}${phone}
      Problem: ${message}
  
    `;

    const templateparams = {
      from_name: name,
      from_email: email,
      to_name: "web",
      message: messageContent, // Include the messageContent here
    };

    emailjs
      .send(serviceid, templateid, templateparams, publickey)
      .then((response) => {
        setIsLoading(false);
        showAlerts();
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [pagecount, setPageCount] = useState(0);
  const images = [ad1, ad2];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, [images.length]);

  const isMobile = useMediaQuery("(max-width:710px)");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePhoneCodeChange = (event) => {
    setPhoneCode(event.target.value);
  };

  const LoadingOverlay = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <CircularProgress className="text-white" />
    </div>
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Mobile view
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleRefresh = () => {
    setSearch("");
    setDetails([]);
    Handleapply(1);
    setSearchResults(null);
    setLoading(true);
    setPage(1);
  };
  const handleID = (res, event) => {
    setDocname(res.doctor_name);
    // setDoctorID(res.doc_id);
    // setHospitalID(res.hos_id);
    // setDoctorDetails(res);
    // setPerson(event);
    if (res.show_booking_button === false) {
      setShowModals(true);
    } else if (res.show_booking_button === true) {
      const url = `${book_Appointment}${res.route_key}`;
      setIframeUrl(url);
      setShowModal(true);
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    if (search.trim() !== "") {
      const filteredDoctors = details.filter((doctor) =>
        doctor.som.toLowerCase().includes(search.toLowerCase())
      );
      // setDetails(filteredDoctors);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (value == 1) {
      const sort = [...details].sort(function (a, b) {
        return b.experience - a.experience;
      });
      setDetails(sort);
    } else if (value == 2) {
      const sort = [...details].sort(function (a, b) {
        return a.experience - b.experience;
      });
      setDetails(sort);
    } else if (value == 3) {
      const sort = [...details].sort(function (a, b) {
        return a.doctor_name.toLowerCase() > b.doctor_name.toLowerCase()
          ? 1
          : -1;
      });
      setDetails(sort);
    }
  };
  const doctorpro = (res) => {
    const profileUrl = `/profile/${res.route_key}`; // Include route_key in the URL

    // Navigate to the profile page with state
    window.open(profileUrl, "_blank");
    // console.log("speciality", res)
  };
  const unicode = "\u20B9";

  const handleregis = () => {
    navigate("/treatment-advice");
  };

  // Function to fetch doctors based on the current page
  const fetchDoctors = async (currentPage) => {
    setLoading(true);
    try {
      // Build the query string conditionally
      const somQuery = selectedFilter ? `?som=${selectedFilter}` : "";

      const response = await ServiceCall.gett(
        `/v2/patient/open/pagination/list/available/doctor/${mode}/${currentPage}/${limit}${somQuery}`
      );

      const newDoctors = response.data.doctors;

      setPageMarking(response.data.page_marking);

      // If fewer doctors than the limit are returned, no more data is available
      if (newDoctors.length < limit) {
        setHasMore(false);
      }

      setDetails((prevDoctors) => [...prevDoctors, ...newDoctors]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching doctors:", error);
      setLoading(false);
    }
  };

  const handlesearch = async () => {
    if (!search.trim()) {
      // Reset to original data when search input is empty
      setSearchResults(null); // Clear search results
      setDetails([]); // Clear previous details
      setPage(1); // Reset page
      setHasMore(true); // Reset hasMore flag
      Handleapply(1); // Reload initial doctors

      return;
    }

    setLoading(true);
    setPage(1); // Reset page
    try {
      // Build the query string conditionally
      const somQuery = selectedFilter ? `&som=${selectedFilter}` : "";
      const body = {
        city: selectedCity,
        experince: parseInt(selectedExperience) || 0,
        rating: parseInt(selectedRating) || 0,
        hospital: selectedHospital,
      };
      const response = await ServiceCall.post(
        `v2/patient/open/pagination/filter/list/available/doctor/${mode}/${page}/${limit}?name=${search}${somQuery}`,
        body
      );

      setPageMarking(response.data.page_marking);

      const searchDoctors = response.data.doctors;
      setSearchResults(searchDoctors);
      setHasMore(searchDoctors.length === limit);
      setLoading(false);
    } catch (err) {
      console.error("Search error:", err);
      setLoading(false);
    }
  };

  const handleSearchInputChange = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);

    if (!searchText.trim()) {
      // When input is cleared, reset to original data
      setSearchResults(null);
      setDetails([]);
      setPage(1);
      setHasMore(true);
      Handleapply(1);
    }
  };

  // useEffect(() =>{
  // const fetchdoctordetail = async () => {
  //   try {
  //     const doctorsdet = await ServiceCall.gett(
  //       `/rxopd/count/doctor/som/${mode}/${selectedFilter} `
  //     );
  //     if (doctorsdet) {
  //       setDoctorDetail(doctorsdet?.data);
  //     }
  //   } catch (err) {}
  // };

  // }, [])
  // Load the next page when user scrolls near the bottom
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100 && !loading && hasMore) {
      // Determine whether to load more search results or default doctors
      if (searchResults) {
        // If search results are active, modify the search API call to support pagination
        handleSearchPagination();
      } else {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handleSearchPagination = async () => {
    setLoading(true);
    try {
      const nextPage = page + 1;

      // Build the query string conditionally
      const somQuery = selectedFilter ? `&som=${selectedFilter}` : "";

      const response = await ServiceCall.gett(
        `/v2/patient/open/pagination/list/available/doctor/${mode}/${nextPage}/${limit}?name=${search}${somQuery}`
      );

      setPageMarking(response.data.page_marking);

      const newSearchResults = response.data.doctors;

      if (newSearchResults.length > 0) {
        // Append new search results to existing results
        setSearchResults((prevResults) => [
          ...prevResults,
          ...newSearchResults,
        ]);
        setPage(nextPage);

        // Check if this is the last page
        setHasMore(newSearchResults.length === limit);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    } catch (err) {
      console.error("Search pagination error:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasMore) {
      if (searchResults) {
        handleSearchPagination();
      } else {
        // Only call Handleapply if filters are applied
        Handleapply(page);
      }
    }
  }, [page]);

  // useEffect(() => {
  //   fetchdoctordetail();
  // }, []);

  // Attach scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  useEffect(() => {
    const lookups = async () => {
      try {
        const lookupdata = await ServiceCall.get(
          "/v2/facility/som/open/lookup"
        );
        if (lookupdata) {
          setLookup(lookupdata.data.records);
        }
      } catch (err) {}
    };
    lookups();
  }, []);
  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const res = await ServiceCall.get(`rxopd/list/mvt/hospitals/${mode}`);

        // Log response for debugging

        if (res?.data?.hospital_list) {
          setHospitals(res.data.hospital_list); // Set the hospitals in state
        } else {
          console.error("Invalid response structure:", res);
        }
      } catch (err) {
        console.error("Error fetching hospitals:", err);
      }
    };

    fetchHospitals();
  }, [mode]); // Re-fetch if mode changes

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const res = await ServiceCall.get(`/v2/patient/list/cities/${appMode}`);

        if (res?.data?.cities) {
          setCities(res.data.cities); // Set the cities in state
        } else {
          console.error("Invalid response structure:", res);
        }
      } catch (err) {
        console.error("Error fetching cities:", err);
      }
    };

    fetchCities();
  }, [appMode]);

  const clearFilters = () => {
    setSelectedCity("");
    setSelectedExperience("");
    setSelectedRating("");
    setSelectedHospital("");
    setIsFiltered(false);
    setPage(1);
    setHasMore(true);
    setDetails([]);
    // fetchDoctors(1);
  };

  const Handleapply = async (currentPage) => {
    setLoading(true);

    try {
      const body = {
        city: selectedCity,
        experince: parseInt(selectedExperience) || 0,
        rating: parseInt(selectedRating) || 0,
        hospital: selectedHospital,
      };

      const res = await ServiceCall.post(
        `v2/patient/open/pagination/filter/list/available/doctor/${mode}/${currentPage}/${limit}?som=${selectedFilter}&name=${search}`,
        body
      );

      if (res) {
        setLoading(false);

        const mark = res.data.page_marking;
        setPageMarking(mark);
        const newDoctors = res.data.doctors;

        if (newDoctors.length > 0) {
          if (currentPage === 1) {
            setDetails(newDoctors);
          } else {
            setDetails((prev) => [...prev, ...newDoctors]);
          }
          setNoDoctorsFound(false);
          setHasMore(newDoctors.length === limit);
          // clearFilters();
        } else {
          if (currentPage === 1) {
            setDetails([]);
            setNoDoctorsFound(true);
          }
          setHasMore(false);
        }
      }
    } catch (err) {
      console.error(err);
      setLoading(false);

      setNoDoctorsFound(true);
      setHasMore(false);
    }
  };

  const handleFilterApply = () => {
    setIsFiltered(true); // Set filter flag to true
    setPage(1); // Reset to first page
    setHasMore(true); // Reset hasMore
    setDetails([]); // Clear existing results
    Handleapply(1); // Call with first page
  };
  // Add a state variable for tracking "no doctors found"

  const displayData = searchResults || details;

  return (
    <>
      {showLoader && <LoadingOverlay />}

      <div>
        <section className="ms-book-banner d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                {/* Search Section */}
                <div className="search-logo-container">
                  <div
                    className="search-bar-wrapper d-flex align-items-center"
                    style={{ position: "relative" }}
                  >
                    {/* Input field for search */}
                    <input
                      type="text"
                      className="form-control"
                      value={search}
                      onChange={handleSearchInputChange}
                      placeholder={
                        activeLogo
                          ? `Search for ${activeLogo}`
                          : "Search Specialities, Doctors, Clinics"
                      }
                      style={{
                        flex: 1,
                        height: "50px",
                        fontSize: "16px",
                        border: "1px solid #ddd",
                      }}
                    />

                    {/* Refresh Button */}

                    {/* Submit button */}
                    <button
                      type="submit"
                      className="ms-secondary-btn"
                      style={{
                        height: "47px",
                        borderRadius: "0 5px 5px 0",
                        backgroundColor: "rgb(1, 140, 146)",
                        color: "#fff",
                        border: "none",
                        padding: isMobile ? "0 1px" : "0 20px",
                        marginTop: "-11px",
                      }}
                      onClick={handlesearch}
                    >
                      <i className="fas fa-search"></i> Search
                    </button>
                  </div>

                  {/* Logos Grid Section */}
                  <div className="logos-grid-container">
                    {/* For Desktop View */}
                    <div className="d-none d-md-flex flex-wrap justify-content-center">
                      {Object.keys(departmentLogos).map((department, index) => (
                        <div
                          key={index}
                          className={`logo-item ${
                            activeLogo === department ? "active" : ""
                          }`}
                          onClick={() => handleLogoClick(department)}
                        >
                          <div
                            className="logo-circle"
                            style={{ marginTop: "20px" }}
                          >
                            <img
                              src={departmentLogos[department]}
                              alt={department}
                              className="logo-img"
                            />
                          </div>
                          <p className="logo-name">{department}</p>
                        </div>
                      ))}
                    </div>

                    {/* For Mobile View */}
                    <div className="d-block d-md-none">
                      <Slider {...sliderSettings}>
                        {Object.keys(departmentLogos).map(
                          (department, index) => (
                            <div key={index} className="logo-item-slider">
                              <div
                                className={`logo-item ${
                                  activeLogo === department ? "active" : ""
                                }`}
                                onClick={() => handleLogoClick(department)}
                              >
                                <div
                                  className="logo-circle"
                                  style={isMobile ? { marginTop: "40px" } : {}}
                                >
                                  <img
                                    src={departmentLogos[department]}
                                    alt={department}
                                    className="logo-img"
                                  />
                                </div>
                                <p className="logo-name">{department}</p>
                              </div>
                            </div>
                          )
                        )}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <span
          style={{
            display: "block",
            width: "100vw", // Full viewport width
            backgroundColor: "rgb(0, 92, 116)",
            color: "#ffffff",
            padding: "6px",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "21px",
            position: "relative", // Position relative for animation
            overflow: "hidden", // Hide overflowing content
          }}
        >
          <div
            className="verifed-line"
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
              animation: "marquee 10s ease-in-out forwards", // Apply animation
            }}
          >
            <img src={verified2} alt="Verified" className="verified-logo2" />
            Verified Care Professionals Only!
          </div>
        </span>

        {/* Inline styles for keyframes animation */}
        <style>
          {`
    @keyframes marquee {
      0% {
        transform: translateX(50%); /* Start off-screen to the right */
      }
      50% {
        transform: translateX(-1%); /* Move to the center */
      }
      100% {
        transform: translateX(-1%); /* End off-screen to the left */
      }
    }
  `}
        </style>

        <div className="booking-section2">
          {/* Static Left Section */}

          <div>
            {/* Mobile-Only Filter Button */}

            {/* Left Section */}
            <div
              className={`left-section3 ${
                isMobileFilterVisible ? "mobile-visible" : ""
              }`}
            >
              {/* Close Button */}
              {isMobileFilterVisible && (
                <button
                  className="close-btn"
                  onClick={toggleMobileFilterVisibility}
                >
                  ✕
                </button>
              )}
              {/* All Specialists */}
              {/* All Specialists */}
              <h5 className="mb-1 ms-font-weight-600">All SPECIALISTS</h5>
              <p className="ms-third-color mb-0">
                {pageMarking} Specialists Found
              </p>

              <div></div>
              {/* Filter Section */}
              <div className="filter-section mt-4">
                {/* City Filter */}
                <div className="filter-category">
                  <h4 className="filter-heading">Cities</h4>
                  <select
                    className="form-select"
                    value={selectedCity}
                    onChange={handleCityChange}
                  >
                    <option value="">Select City</option>
                    {cities.length > 0 ? (
                      cities.map((city, index) => (
                        <option key={index} value={city.toLowerCase()}>
                          {city}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No Cities Available
                      </option>
                    )}
                  </select>
                </div>

                {/* Experience Filter */}
                <div className="filter-category">
                  <h4 className="filter-heading">Experience</h4>
                  <select
                    className="form-select"
                    value={selectedExperience}
                    onChange={handleExperienceChange}
                  >
                    <option value="">All</option>
                    {[20, 10, 5, 2].map((range, index) => (
                      <option key={index} value={range}>
                        {range}+ years
                      </option>
                    ))}
                  </select>
                </div>

                {/* Ratings Filter */}
                <div className="filter-group">
                  <h4 className="filter-heading">Ratings</h4>
                  <select
                    className="form-select"
                    value={selectedRating}
                    onChange={handleRatingChange}
                    style={{
                      marginBottom: "20px",
                      position: "relative",
                      zIndex: 1000,
                    }}
                  >
                    <option value="">All</option>
                    {[
                      { label: "4.5 - 5 ", value: 5 },
                      { label: "4 - 4.5 & above", value: 4 },
                      { label: "3 - 4 & above", value: 3 },
                      { label: "2 - 3 & above", value: 2 },
                    ].map((rating, index) => (
                      <option key={index} value={rating.value}>
                        {rating.label}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Hospitals Filter */}
                <div className="filter-category">
                  <h4 className="filter-heading">Hospitals</h4>
                  <select
                    className="form-select open-down"
                    value={selectedHospital}
                    onChange={handleHospitalChange}
                  >
                    <option value="">Select Hospital</option>
                    {hospitals.length > 0 ? (
                      hospitals.map((hospital, index) => (
                        <option key={index} value={hospital.faculty_id}>
                          {hospital.name} {/* Display the name */}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No Hospitals Available
                      </option>
                    )}
                  </select>
                </div>

                {/* Availability Filter */}
                <div className="filter-group">
                  <h4 className="filter-heading">Availability</h4>
                  <div className="checkbox-group">
                    <label>
                      <input type="checkbox" /> In-person Consultation
                    </label>
                    <label>
                      <input type="checkbox" /> Virtual Consultation
                    </label>
                  </div>
                </div>
                {/* Apply Button */}
                <button className="apply-btn" onClick={handleFilterApply}>
                  Apply
                </button>

                {/* Optionally, render the list of doctors */}
                {details.length > 0 && (
                  <div className="doctor-list">
                    {details.map((doctor, index) => (
                      <p key={index}>{doctor.name}</p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Mobile-Only Filter Button */}

          {/* Scrollable Right Section */}
          <div className="right-section3">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {!isMobileFilterVisible && (
                <button
                  className="filter-btn"
                  onClick={toggleMobileFilterVisibility}
                >
                  {/* <FilterListIcon style={{ marginRight: "5px" }} /> */}
                  Filters
                  <span>
                    <FilterListIcon />{" "}
                  </span>
                </button>
              )}

              {!isModalOpen /* Ensure Offer button only shows when the modal is closed */ && (
                <button
                  className="offer-button2"
                  onClick={toggleModal}
                  style={{ marginTop: "-20px" }}
                >
                  Offers
                  <NotificationImportantIcon
                    style={{ fontSize: "2rem", color: "white" }}
                  />
                </button>
              )}

              {isModalOpen && (
                <OfferModal
                  images={images}
                  currentImageIndex={currentImageIndex}
                  handleregis={() => console.log("Consult Now Clicked!")}
                  handleClose={() => setIsModalOpen(false)}
                />
              )}
            </div>

            <div className="sort-container">
              <p className="ms-third-color mb-1 ms-dark-color fw-normal ms-sort-txt">
                Sort by:
              </p>
              <select
                onChange={(e) => handleChange(e)}
                className="sort-dropdown"
              >
                <option value="0">None</option>
                <option value="1">High to Low</option>
                <option value="2">Low to High</option>
                <option value="3">Name</option>
              </select>
              <br></br>
            </div>
            <div>
              {/* Icon Button */}

              {/* Modal */}
              {/* {noDoctorsFound && (
              <p style={{ fontWeight: "bold", color: "#000" }}>
                No doctors found
              </p>
            )} */}

              {/* Optionally, render the list of doctors */}
              {details.length > 0 && (
                <div className="doctor-list">
                  {details.map((doctor, index) => (
                    <p key={index}>{doctor.name}</p>
                  ))}
                </div>
              )}
            </div>
            {displayData.length === 0 ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: "100%" }}
              >
                {/* <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div> */}
              </div>
            ) : (
              <div className="doctor-card2s-container">
                {displayData.map((res, key) => (
                  <div
                    key={res.doc_id || key}
                    className="doctor-card2"
                    onClick={() => doctorpro(res)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="doctor-card2-left">
                      <div className="image-container">
                        <img
                          src={res.doctor_profile_pic}
                          alt="Doctor"
                          className="doctor-img"
                          onError={(e) =>
                            (e.target.src = "/path/to/placeholder.jpg")
                          }
                        />
                        <img
                          src={verified}
                          alt="Verified"
                          className="verified-logo"
                        />
                      </div>
                    </div>

                    <div className="doctor-card2-right">
                      <h3 className="doctor-name">{res.doctor_name}</h3>

                      {/* Hospital Information */}
                      {res.hospital && (
                        <p>
                          <span className="doctor-heading">Hospital:</span>{" "}
                          {res.hospital}
                        </p>
                      )}

                      {/* Speciality */}
                      <p>
                        <span className="doctor-heading">Speciality:</span>{" "}
                        {res.speciality}
                      </p>

                      {/* Experience */}
                      {res.experience > 0 && (
                        <p>
                          <span className="doctor-heading">Experience:</span>{" "}
                          {res.experience} Year
                          {res.experience !== 1 ? "s" : ""} Experience
                        </p>
                      )}

                      {/* Schedule */}
                      {res.availability_today?.clinic_days && (
                        <p>
                          <span className="doctor-heading">Schedule:</span>{" "}
                          {res.availability_today.clinic_days}
                        </p>
                      )}

                      {/* Clinic Hours */}
                      {res.availability_today?.clinic_start_time &&
                        res.availability_today?.clinic_end_time && (
                          <p>
                            <span className="doctor-heading">
                              Clinic Hours:
                            </span>{" "}
                            {res.availability_today.clinic_start_time} -{" "}
                            {res.availability_today.clinic_end_time}
                          </p>
                        )}

                      {/* Rating */}
                      <p>
                        <span className="doctor-heading">Rating:</span>
                        <span className="rating-stars">
                          {Array.from({
                            length: Math.floor(res.google_rating || 0),
                          }).map((_, i) => (
                            <i key={i} className="fa fa-star star-filled"></i>
                          ))}
                          {res.google_rating % 1 !== 0 && (
                            <i className="fa fa-star-half-alt star-half"></i>
                          )}
                        </span>
                        <span className="rating-value">
                          ({res.google_rating || "No Rating"})
                        </span>
                      </p>

                      <div className="action-buttons">
                        <button
                          className="appointment-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleID(res, "True", "Clinic");
                          }}
                        >
                          Book Appointment
                        </button>
                      </div>
                    </div>

                    <div className="map-photo-container">
                      <img
                        src={mapphoto || "/path/to/default-map.jpg"}
                        alt="Map Location"
                        className="map-photo"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (res?.google_maps_url) {
                            window.open(res.google_maps_url, "_blank");
                          } else {
                            console.warn("Google Maps URL is not available");
                          }
                        }}
                      />

                      {/* Location and External Link Icon */}
                      <div
                        className="location-container"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.open(res.google_maps_url, "_blank");
                        }}
                      >
                        <span className="location-text">
                          {res.location || "Get direction"}
                        </span>
                        <CallMadeIcon className="external-link-icon" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {loading && (
              <div
                className="spinner-border"
                role="status"
                style={{ marginTop: "-40px" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}

            {/* Show message when no more doctors are available */}
            {!hasMore && (
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                No more doctors to load.
              </p>
            )}
          </div>

          <div
            className={`add-section3 ${
              isMobileOfferVisible ? "mobile-visible" : ""
            }`}
            onClick={handleregis}
            style={{ cursor: "pointer" }} // Indicates that the container is clickable
          >
            {/* Close Button */}
            {/* Uncomment if needed */}
            {/* <button className="close-btn" onClick={(e) => {
        e.stopPropagation(); // Prevent click propagation
        handleCloseSection();
      }}
    >
      ✕
  </button> */}

            <div className="ad-header">
              <h5>
                LIMITED TIME OFFER! Get{" "}
                <span style={{ color: "rgb(255, 203, 37)" }}>FREE</span>{" "}
                treatment advice from our experts
                <button
                  className="handlrs"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent onClick
                    handleregis();
                  }}
                  style={{
                    marginTop: "0px",
                    marginBottom: "20px",
                    marginLeft: "7px",
                  }}
                >
                  <span style={{ fontSize: "17px" }}>CONSULT NOW</span>
                </button>
              </h5>
            </div>

            {/* Ad Images */}
            <div className="ad-images">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Ad ${index + 1}`}
                  className={`ad-image ${
                    index === currentImageIndex ? "active" : ""
                  }`}
                />
              ))}
            </div>
          </div>

          {show && (
            <div className="appointment__screen">
              <AppointmentScreen
                doctor={doctorID}
                hospital={hospitalID}
                isPerson={showPerson}
                AllDoctorDetails={doctorDetails}
                close={handleClose}
              />
            </div>
          )}
          {showAlert && (
            <CenteredAlert
              message="We have received your request, please allow us up-to 24 Hrs. to come back to you."
              onClose={() => setShowAlert(false)}
            />
          )}
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // style={{width: "35%", left: "30%"}}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Book Appointment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe
                src={iframeUrl}
                width="100%"
                height="650px"
                title="Appointment Booking"
                frameBorder="0"
                allowFullScreen
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={showModals}
            onHide={() => setShowModals(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // style={{width: "35%", left: "30%"}}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.4rem",
                fontWeight: "600",
                marginBottom: "1rem",
                color: "#005c74",
              }}
            >
              Book Appointment
            </span>

            <h1
              style={{
                fontSize: "1.4rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              We will call you to confirm your booking with the {docname}
            </h1>
            <Modal.Body>
              <form style={{ textAlign: "center" }} onSubmit={handlesubmit}>
                <TextField
                  id="firstName"
                  name="first_name"
                  label="Full Name"
                  variant="filled"
                  style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  fullWidth
                  className={classes.nameField}
                />
                <FormControl
                  variant="filled"
                  fullWidth
                  className={classes.nameField}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="w-23"
                      onChange={handlePhoneCodeChange}
                      value={phoneCode} // Concatenate phoneCode and phone here
                      classes={{ select: classes.select }}
                    >
                      {countriesList &&
                        countriesList.map((item, index) => (
                          <MenuItem
                            value={item.code}
                            key={item.code + "-" + index}
                          >
                            {item.code}
                          </MenuItem>
                        ))}
                    </Select>

                    <InputLabel
                      htmlFor="phoneNumber"
                      style={{ marginLeft: "101px" }}
                    >
                      Phone Number*
                    </InputLabel>
                    <Input
                      id="phoneNumber"
                      name="phone"
                      label="Phone Number"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      error={phoneError}
                      style={{
                        flex: 1,
                        marginLeft: 6,
                        width: "100px",
                        marginBottom: "1rem",
                      }} // Adjust the margin and flex properties
                      className={classes.nameField}
                    />
                  </div>
                  {phoneError && (
                    <FormHelperText error>
                      Please enter a valid phone number.
                    </FormHelperText>
                  )}
                </FormControl>

                <TextField
                  id="chiefComplaints"
                  name="message"
                  label="Decribe Your Medical Problem"
                  variant="filled"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  fullWidth
                  style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                  className={classes.nameField}
                />

                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                  style={{ position: "relative" }}
                >
                  {isLoading ? (
                    <>
                      <CircularProgress
                        size={24}
                        style={{
                          color: "blue",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                      <span style={{ visibility: "hidden" }}>Submit</span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </form>
            </Modal.Body>
          </Modal>
          <div className="whatsapp-block" style={{ marginBottom: "4rem" }}>
            <a
              href="https://api.whatsapp.com/send?phone=+917703934446&text=Hello%20RxOne,%20Could%20you%20please%20call%20back."
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp} alt="whtsp" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking2;
